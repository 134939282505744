@import "../vars";

.header {
  .wrap {
    position: fixed;
    z-index: 777;
    width: 100%;
    height: 80px;
    top: 0;
    left: 0;
    background-color: $white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
}

// Media Queries
//====================================================================
// 1201-1300px
//====================================================================
@media screen and (max-width: 1300px) {
  .header {
    .container {
      width: 1180px;

      .logo {
        width: 350px;

        img {
          height: 18px;
        }
      }

      .menu {
        width: 92%;
      }
    }
  }
}

//====================================================================
// 992-1200px
//====================================================================
@media screen and (max-width: 1200px) {
  .header {
    .navigation {
      display: none !important;
    }

    .container {
      position: relative;
      width: 940px;

      .logo {
        width: 250px;

        img {
          height: 18px;
        }
      }

      .lang {
        margin-right: 70px;
      }
    }

    #burger {
      display: block !important;
    }

    .navigate {
      display: flex !important;
    }
  }
}

//====================================================================
// 768-992px
//====================================================================
@media screen and (max-width: 992px) {
  .header {
    .container {
      width: 720px;
    }
  }
}

//====================================================================
// 576-768px
//====================================================================
@media screen and (max-width: 768px) {
  .header {
    .container {
      width: 540px;
    }
  }
}

//====================================================================
// 320-576px
//====================================================================
@media screen and (max-width: 576px) {
  .header {
    .container {
      width: 340px;

      .logo {
        width: auto;
        margin-top: -2px;

        img:nth-child(2) {
          display: none;
        }
      }

      .lang {
        width: 90px;

        button {
          font-size: 1.1rem;
          padding: 5px 8px;
        }
      }
    }
  }
}
