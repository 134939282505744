@import "../../components/vars";

#festival {
  padding-bottom: 50px;
}

// MediaQueries
//====================================================================
// 1200-1350px
//====================================================================
@media screen and(max-width: 1350px) {
  .container {
    width: 1180px;
  }
}

//====================================================================
// 992-1200px
//====================================================================
@media screen and(max-width: 1200px) {
  .container {
    width: 960px;
  }

  #festival-about {
    .wrap {
      .wrapper {
        .right {
          order: 3 !important;
        }
      }
    }
  }
}

//====================================================================
// 768-992px
//====================================================================
@media screen and(max-width: 992px) {
  .container {
    width: 720px;
  }
}

//====================================================================
// 576-768px
//====================================================================
@media screen and(max-width: 768px) {
  .container {
    width: 540px;
  }
}

//====================================================================
// 340-576px
//====================================================================
@media screen and(max-width: 576px) {
  .container {
    width: 340px;
  }
}
