@import "../../components/vars";

#cookies {
  padding-bottom: 50px;

  .offer {
    h1, h3 {
      color: $white;
    }
  }
}
