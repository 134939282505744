@import "../../components/vars";

.list-image {
  .wrap {
    position: relative;
  }

  .dn {
    display: none !important;
  }

  .wrapper {
    .left {
      ul {
        li {
          margin-bottom: 35px;

          &:nth-child(1) h3::before {
            background-color: $green;
            content: "01";
          }

          &:nth-child(2) h3::before {
            background-color: $yellow;
            content: "02";
          }

          &:nth-child(3) h3::before {
            background-color: $blue;
            content: "03";
          }

          h3 {
            @include triangle;
            font-size: 1.3rem;
          }

          p {
            position: relative;
            padding-left: 40px;

            &::before {
              content: "\2192";
              display: block;
              position: absolute;
              top: -7px;
              left: 0;
              font-size: 25px;
              color: $green;
            }
          }

          span {
            display: block;
            color: $text;
            margin-top: 10px;
            padding-left: 60px;
          }

          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

// MediaQueries
//====================================================================
// 1200-1350px
//====================================================================
@media screen and(max-width: 1350px) {
  .container {
    width: 1180px;
  }

  .services {
    .card-item {
      width: 570px;
    }
  }
}

//====================================================================
// 992-1200px
//====================================================================
@media screen and(max-width: 1200px) {
  .container {
    width: 960px;
  }

  .services .card-item {
    width: 465px;
  }
}

//====================================================================
// 768-992px
//====================================================================
@media screen and(max-width: 992px) {
  .container {
    width: 720px;
  }

  .services .card-item {
    width: 100%;

    h3 {
      font-size: 2rem;
    }

    p {
      font-size: 1.3rem;
    }

    a {
      font-size: 1.3rem;
      padding: 19px 50px !important;
    }

    &:nth-child(3) {
      margin-bottom: 20px !important;
    }

    img {
      width: 100%;
    }
  }

  #radius {
    .wrapper {
      .left {
        order: 1 !important;
      }

      .right {
        order: 2 !important;
      }
    }
  }
}

//====================================================================
// 576-768px
//====================================================================
@media screen and(max-width: 768px) {
  .container {
    width: 540px;
  }

  .services .card-item {
    .image-max {
      display: none;
    }

    .image-min {
      display: block !important;
    }

    h3 {
      font-size: 1.7rem;
    }

    p {
      font-size: 1.1rem;
    }

    a {
      font-size: 1.1rem;
      padding: 18px 45px !important;
    }
  }
}

//====================================================================
// 340-576px
//====================================================================
@media screen and(max-width: 576px) {
  .container {
    width: 340px;
  }

  .services .card-item {
    .text {
      margin-top: -8px;
      width: 95% !important;
    }

    h3 {
      font-size: 1.3rem;
    }

    p {
      font-size: 1rem;
      margin: 10px auto 20px !important;
    }

    a {
      font-size: 1rem;
      padding: 15px 25px !important;
    }
  }
}
