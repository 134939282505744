@import "../../components/vars";

.single-container {
  margin-bottom: 100px;

  &:nth-last-child(1) {
    margin-bottom: 50px;
  }

  &-panel {
    padding: 50px 0 0;
  }
}
