@import "../../components/vars";

#not-found {
  padding-bottom: 0;

  .offer {
    padding-bottom: 0;

    h1, h3 {
      color: $white;
    }
  }
}
