@import "../vars";

.modal {
  position: fixed;
  z-index: 777;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  &-content {
    width: 520px;
    height: auto;
    padding: 50px;
    background-color: #e7e7e7;
    transform: scale(0.3);
    transition: 0.5s all;
    @include border-radius(20px);

    &.active {
      transform: scale(1);
    }

    form {
      .close {
        position: absolute;
        right: 25px;
        top: 25px;
        font-size: 30px;
        cursor: pointer;
        line-height: 1;

        &:hover {
          color: $hover;
        }
      }

      label {
        position: relative;
        color: gray;
        font-size: 0.8rem;
      }

      input {
        width: 100%;
        margin-top: 5px;
        margin-bottom: 15px;
        border: none;
        background-color: #fff;
        color: #000;
        padding: 8px;
        @include border-radius(25px);
      }

      textarea {
        margin-top: 5px;
        margin-bottom: 25px;
        width: 100%;
        height: 100px;
        resize: none;
        padding: 8px;
        border: none !important;
        @include border-radius(10px);
      }

      .btn {
        margin-left: auto;
        width: 220px;
        height: 55px;
      }
    }
  }
}

// MediaQueries
//====================================================================
// 1200-1350px
//====================================================================
@media screen and(max-width: 1350px) {
  .container {
    width: 1180px;
  }
}

//====================================================================
// 992-1200px
//====================================================================
@media screen and(max-width: 1200px) {
  .container {
    width: 960px;
  }
}

//====================================================================
// 768-992px
//====================================================================
@media screen and(max-width: 992px) {
  .container {
    width: 720px;
  }
}

//====================================================================
// 576-768px
//====================================================================
@media screen and(max-width: 768px) {
  .container {
    width: 540px;
  }
}

//====================================================================
// 340-576px
//====================================================================
@media screen and(max-width: 576px) {
  .container {
    width: 340px;
  }

  .modal-content {
    width: 90%;
    padding: 35px 20px;
  }


}
