@import "../../components/vars";

#technics {
  .container {
    #technics-slider .swiper-container {
      filter: none !important;

      .swiper-slide img {
        @include border-radius(0 !important);
      }
    }
  }
}
