@import "../vars";

.lang {
  display: flex;
  width: 100px;

  button {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
    color: $black;
    width: auto;
    height: auto;
    background-color: #fff;
    border: none;
    padding: 5px 10px;

    &:hover {
      color: $hover;
    }

    &.active {
      pointer-events: none;
      color: $active !important;
    }
  }
}
