@import "../vars";

.btn {
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  padding: 15px 25px 13px;
  text-transform: uppercase;
  background-color: $btn;
  color: $white;
  border: 0;
  @include transition(0.3s);
  @include border-radius(100px);

  &:hover {
    background-color: $hover;
  }
}

//MediaQueries
//====================================================================
// 1200-1350px
//====================================================================
@media screen and(max-width: 1350px) {
  .container {
    width: 1180px;
  }
}

//====================================================================
// 992-1200px
//====================================================================
@media screen and(max-width: 1200px) {
  .container {
    width: 960px;
  }
}

//====================================================================
// 768-992px
//====================================================================
@media screen and(max-width: 992px) {
  .container {
    width: 720px;
  }
}

//====================================================================
// 576-768px
//====================================================================
@media screen and(max-width: 768px) {
  .container {
    width: 540px;
  }

  .btn {
    padding: 18px 25px 16px;
  }
}

//====================================================================
// 340-576px
//====================================================================
@media screen and(max-width: 576px) {
  .container {
    width: 340px;
  }
}
