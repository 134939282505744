@import "../../components/vars";

.card-item {
  position: relative;
  margin-bottom: 3%;

  img {
    @include border-radius(20px);
  }

  .text {
    text-align: center;
    h3 {
      color: $white;
    }
    p {
      margin: 25px auto 35px;
      color: $white;
    }
    a {
      padding: 15px 50px;
      color: $white;
      @include border-radius(50px);

      &:hover {
        background-color: $hover !important;
        color: $black;
      }
    }
  }

  &:nth-child(1) {
    .text {
      width: 465px;
    }
  }
  &:nth-child(2) {
    .text {
      width: 400px;
    }
  }
  &:nth-child(3) {
    .text {
      width: 470px;
    }
  }
  &:nth-child(4) {
    .text {
      width: 450px;
    }
  }
}
