@import "../../components/vars";

#ground {
  .list-image-text ul li img {
    height: auto;
  }

  #method {
    .wrapper {
      .left ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
          width: 50%;
        }
      }
    }
  }
}

// MediaQueries
//====================================================================
// 1200-1350px
//====================================================================
@media screen and(max-width: 1350px) {
  .container {
    width: 1180px;
  }
}

//====================================================================
// 992-1200px
//====================================================================
@media screen and(max-width: 1200px) {
  .container {
    width: 960px;
  }
}

//====================================================================
// 768-992px
//====================================================================
@media screen and(max-width: 992px) {
  .container {
    width: 720px;
  }

  #ground {
    #method {
      .wrapper {
        .left {
          order: 1 !important;
        }

        .right {
          order: 2 !important;
        }
      }
    }
  }
}

//====================================================================
// 576-768px
//====================================================================
@media screen and(max-width: 768px) {
  .container {
    width: 540px;
  }
}

//====================================================================
// 340-576px
//====================================================================
@media screen and(max-width: 576px) {
  .container {
    width: 340px;
  }

  #ground {
    #method {
      .wrapper {
        .left {
          ul {
            li {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}
