@import "../../components/vars";

.footer {
  position: relative;

  &-up {
    padding: 100px 0;
    background-color: $gray;

    .logo {
      width: 270px;

      img {
        height: 20px;
      }
    }

    .wrap {
      display: flex;
      flex-wrap: wrap;
      margin-top: 50px;
      justify-content: space-between;

      &-item {
        width: 290px;

        h3 {
          color: #000;
        }

        ul {
          margin-top: 25px;

          li {
            margin: 13px 0;

            a {
              color: $text;

              &:hover {
                color: $hover;
              }
            }
          }
        }

        &:nth-child(1) {

          ul {
            margin-top: 40px;

            li {
              margin: 20px 0;

              a {
                display: flex;
                align-items: center;

                img {
                  width: 35px;
                  height: 35px;
                  margin-right: 10px;
                }
              }

              &:nth-child(2) {
                margin: 25px 0 !important;
              }
            }
          }
        }

        &:nth-child(2) {
          .active {
            color: $active;
          }
        }

        &:nth-child(3) {
          img {
            margin: 25px 0 35px;
            height: 120px;
            @include border-radius(0 !important);
          }
        }

        &:nth-child(4) {
          ul {
            width: 140px;
            display: flex;
            justify-content: space-between;

            li {
              margin: 2px 0;

              a {
                img {
                  height: 30px;
                  @include transition(0.3s);

                  &:hover {
                    @include scale(1.1);
                  }
                }
              }
            }
          }


        }

        .btn {
          padding: 20px 25px 18px;
        }
      }
    }
  }

  &-down {
    background-color: #fff;
    padding: 20px 0 17px;

    .wrap {
      flex-wrap: wrap;
      justify-content: space-between;

      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      a {
        color: $text;
        margin-top: 2px;

        &.active {
          color: $active !important;
        }

        &:hover {
          color: $hover;
        }
      }
    }
  }
}

// Media Queries
// ====================================================================
// 1200-1350px
//====================================================================
@media screen and (max-width: 1350px) {
  .container {
    width: 1180px;
  }
}

//====================================================================
// 992-1200px
//====================================================================
@media screen and (max-width: 1200px) {
  .container {
    width: 960px;
  }

  .footer-down .wrap ul {
    justify-content: space-around;

    li {
      line-height: 2;
    }
  }
}

//====================================================================
// 768-992px
//====================================================================
@media screen and (max-width: 992px) {
  .container {
    width: 720px;
  }
}

//====================================================================
// 576-768px
//====================================================================
@media screen and (max-width: 768px) {
  .container {
    width: 540px;
  }

  .footer-up {
    .wrap {
      margin-top: 25px;
    }

    .wrap-item {
      width: 100%;

      h3 {
        margin-top: 20px;
      }

      &:nth-child(3) {
        order: 4;
        margin-top: 10px;
      }

      &:nth-child(4) {
        order: 3;
      }
    }
  }
}

//====================================================================
// 340-576px
//====================================================================
@media screen and (max-width: 576px) {
  .container {
    width: 340px;
  }

  .footer {
    .footer-up {
      padding: 50px 0;
    }

    .footer-down .wrap ul {
      flex-direction: column;
    }
  }
}
