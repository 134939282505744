@import "../../components/vars";

.list-image-text {
  h2 {
    text-align: center;
    width: 80%;
    margin: auto;

    &::after {
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  ul {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    li {
      width: 33%;
      margin: 20px 0;
      padding: 0 35px;

      h3 {
        font-size: 1.3rem;
        text-align: center;
      }

      &:nth-child(1) {
        h3, b {
          color: $green;
        }

      }

      &:nth-child(2) {
        h3, b {
          color: $yellow;
        }
      }

      &:nth-child(3) {
        h3, b {
          color: $blue;
        }
      }

      &:nth-child(4) {
        h3, b {
          color: $brown;
        }
      }

      &:nth-child(5) {
        h3, b {
          color: $blue;
        }
      }

      &:nth-child(6) {
        h3, b {
          color: $green;
        }
      }

      &:nth-child(7) {
        h3, b {
          color: $black;
        }
      }

      img {
        margin: 0 auto;
        height: 80px;
        @include border-radius(0 !important);
      }

      p {
        display: block;
        margin-top: 20px;
        text-align: center;
      }
    }
  }
}

// MediaQueries
//====================================================================
// 1200-1350px
//====================================================================
@media screen and(max-width: 1350px) {
  .container {
    width: 1180px;
  }
}

//====================================================================
// 992-1200px
//====================================================================
@media screen and(max-width: 1200px) {
  .container {
    width: 960px;
  }
}

//====================================================================
// 768-992px
//====================================================================
@media screen and(max-width: 992px) {
  .container {
    width: 720px;
  }
}

//====================================================================
// 576-768px
//====================================================================
@media screen and(max-width: 768px) {
  .container {
    width: 540px;
  }

  .list-image-text {
    h2 {
      text-align: left;
      width: 100%;

      &::after {
        left: 0;
        transform: translate(0, 0);
      }
    }

    ul {
      li {
        width: 50%;
        padding: 0 25px;
      }
    }
  }
}

//====================================================================
// 340-576px
//====================================================================
@media screen and(max-width: 576px) {
  .container {
    width: 340px;
  }

  .list-image-text ul li {
    width: 100%;
    padding: 0 20px;
  }
}
