@import "../../components/vars";

#complex {
  .lng-complex_title_h2 {
    text-align: center;
    margin: auto auto 100px;

    &::after {
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  #phases {
    ul li:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .lng-complex_non_standard_quality_h3 {
    text-align: center;
    color: $text;

    b {
      color: $green;
    }
  }

  #difficulties-farmers {
    padding-bottom: 0;
  }
}

// MediaQueries
//====================================================================
// 1200-1350px
//====================================================================
@media screen and(max-width: 1350px) {
  .container {
    width: 1180px;
  }
}

//====================================================================
// 992-1200px
//====================================================================
@media screen and(max-width: 1200px) {
  .container {
    width: 960px;
  }
}

//====================================================================
// 768-992px
//====================================================================
@media screen and(max-width: 992px) {
  .container {
    width: 720px;
  }

  #complex {
    #phases,
    #payment-harvest,
    #non-standard-quality {
      .wrap {
        .wrapper {
          .right {
            order: 3 !important;
          }
        }
      }
    }
  }
}

//====================================================================
// 576-768px
//====================================================================
@media screen and(max-width: 768px) {
  .container {
    width: 540px;
  }
}

//====================================================================
// 340-576px
//====================================================================
@media screen and(max-width: 576px) {
  .container {
    width: 340px;
  }
}
