@import "../../components/vars";

.slider {
  position: relative;

  .swiper-container {
    filter: drop-shadow(5px 5px 25px rgba(0, 0, 0, .25));

    .swiper-slide {
      img {
        cursor: pointer;
        margin: 0 auto;
        width: auto !important;
        @include border-radius(20px);
      }
    }

    .swiper-pagination {
      bottom: 0;
      left: 50%;
      @include transform(-50%, 0);

      span {
        width: 3px;
        height: 3px;
        background-color: $black;
        margin: 0 8px;
      }
    }
  }

  .nav-slider {
    margin-left: auto;
    width: 130px;
    display: flex;
    justify-content: space-between;
    height: 22px;

    .prev,
    .next {
      position: relative;
      width: 50px;
      height: 50px;
      background-color: $btn;
      @include border-radius(50%);
      @include transition(0.2s);

      &:hover {
        background-color: $hover;
      }

      &::after {
        position: absolute;
        z-index: 1;
        display: block;
        color: $white;
        font-size: 2rem;
      }
    }

    .prev::after {
      content: '\276E';
      margin-left: -3px;
    }

    .next::after {
      content: '\276F';
      margin-left: 3px;
    }
  }
}
