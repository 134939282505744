$font-custom: "Geometria", sans-serif;

$white: #fff;
$black: #000000;
$text: #5d5d5d;

$btn: #5bae62;
$hover: #ffc363;
$active: #ff9100;

$yellow: #ffb700;
$green: #00af41;
$blue: #0082ca;
$brown: #bc6124;
$gray: #f8f8f8;

@mixin border-radius($br) {
  border-radius: $br;
  -webkit-border-radius: $br;
  -moz-border-radius: $br;
  -ms-border-radius: $br;
  -o-border-radius: $br;
}

@mixin filter-gray($fg) {
  -webkit-filter: grayscale($fg);
  -moz-filter: grayscale($fg);
  -ms-filter: grayscale($fg);
  -o-filter: grayscale($fg);
  filter: grayscale($fg);
  -webkit-filter: gray;
  filter: gray;
}

@mixin transition($tr) {
  transition: all $tr ease-in-out;
  -webkit-transition: all $tr ease-in-out;
  -moz-transition: all $tr ease-in-out;
  -ms-transition: all $tr ease-in-out;
  -o-transition: all $tr ease-in-out;
}

@mixin transform($h, $v) {
  transform: translate($h, $v);
  -webkit-transform: translate($h, $v);
  -moz-transform: translate($h, $v);
  -ms-transform: translate($h, $v);
  -o-transform: translate($h, $v);
}

@mixin translate($h, $v) {
  transform: translate($h, $v);
  -webkit-transform: translate($h, $v);
  -moz-transform: translate($h, $v);
  -ms-transform: translate($h, $v);
  -o-transform: translate($h, $v);
}

@mixin rotate($val) {
  transform: rotate($val);
  -webkit-transform: rotate($val);
  -moz-transform: rotate($val);
  -ms-transform: rotate($val);
  -o-transform: rotate($val);
}

@mixin scale($sc) {
  transform: scale($sc);
  -webkit-transform: scale($sc);
  -moz-transform: scale($sc);
  -ms-transform: scale($sc);
  -o-transform: scale($sc);
}

@mixin triangle {
  position: relative;
  padding-left: 60px;

  &::before {
    display: block;
    position: absolute;
    z-index: -1;
    left: 0;
    font-size: 1.8rem;
    width: 50px;
    height: 50px;
    border-radius: 55px 5px 5px 5px;
    -webkit-border-radius: 55px 5px 5px 5px;
    -moz-border-radius: 55px 5px 5px 5px;
    -ms-border-radius: 55px 5px 5px 5px;
    -o-border-radius: 55px 5px 5px 5px;
  }
}
