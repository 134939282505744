@import "../../components/vars";

.text-col-box {
  .container {
    h2 {
      text-align: center;
      margin: auto;

      &::after {
        left: 50%;
        transform: translate(-50%, 0);
      }
    }

    ul {
      margin-top: 75px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        width: 23%;
        padding: 35px 0 30px;
        border-top: 5px solid;
        border-bottom: 1px solid gray;

        &:nth-child(1) {
          border-top-color: $brown;

          b {
            color: $brown;
          }
        }

        &:nth-child(2) {
          border-top-color: $blue;

          b {
            color: $blue;
          }
        }

        &:nth-child(3) {
          border-top-color: $green;

          b {
            color: $green;
          }
        }

        &:nth-child(4) {
          border-top-color: $black;

          b {
            color: $black;
          }
        }

        h3 {
          height: 80px;
        }
      }
    }
  }
}

// MediaQueries
//====================================================================
// 1200-1350px
//====================================================================
@media screen and(max-width: 1350px) {
  .container {
    width: 1180px;
  }
}

//====================================================================
// 992-1200px
//====================================================================
@media screen and(max-width: 1200px) {
  .container {
    width: 960px;
  }
}

//====================================================================
// 768-992px
//====================================================================
@media screen and(max-width: 992px) {
  .container {
    width: 720px;
  }

  .text-col-box .container ul li {
    width: 46%;
    margin-bottom: 50px;
  }
}

//====================================================================
// 576-768px
//====================================================================
@media screen and(max-width: 768px) {
  .container {
    width: 540px;
  }
}

//====================================================================
// 340-576px
//====================================================================
@media screen and(max-width: 576px) {
  .container {
    width: 340px;
  }

  .text-col-box .container ul li {
    width: 100%;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
}
