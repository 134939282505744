@import "../vars";

.slide-item {
  box-shadow: none;
  filter: none;

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .left {
      width: 570px;
      position: relative;
      margin: auto 0;

      &-bg {
        margin: auto;
        width: 460px;
        height: 460px;
        border-radius: 250px 0 0 0;
        background-color: $green;
      }

      img {
        position: absolute;
        z-index: 1;
        width: auto;
        max-height: 100%;
        top: 50%;
        left: 50%;
        bottom: 2%;
        @include transform(-50%, -50%);
      }
    }

    .right {
      width: 670px;
      margin: auto 0;

      h3 {
        font-size: 2rem;
        color: $black;
        font-weight: bold;
      }

      h4 {
        color: $text;
        font-weight: lighter;
      }

      ul {
        margin-top: 25px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
          display: flex;
          margin: 12px 0;
          width: 50%;

          img {
            height: 22px;
            margin: 0;
            @include border-radius(0 !important);
          }

          p {
            margin-left: 10px;
            font-size: .9rem;
            color: $text;

            b {
              font-size: inherit;
              color: $green;
            }
          }
        }
      }

      .box-text {
        margin-top: 30px;
        width: 95%;
        padding: 30px 30px 30px 20px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        @include border-radius(10px);

        p {
          position: relative;
          padding-left: 20px;
          font-size: .8rem;
          color: $text;
        }
      }
    }
  }
}

// MediaQueries
//====================================================================
// 1200-1350px
//====================================================================
@media screen and(max-width: 1350px) {
  .container {
    width: 1180px;
  }

  .slide-item {
    .wrapper {
      .left {
        width: 570px;
      }

      .right {
        width: 570px;
      }
    }
  }
}

//====================================================================
// 992-1200px
//====================================================================
@media screen and(max-width: 1200px) {
  .container {
    width: 960px;
  }

  .slide-item {
    padding-bottom: 10px;

    .wrapper {
      .left {
        width: 430px;

        &-bg {
          width: 380px;
          height: 380px;
        }
      }

      .right {
        width: 500px;

        h3 {
          font-size: 1.3rem;
        }

        h4 {
          font-size: .8rem;
        }

        ul {
          margin-top: 15px;

          li p {
            font-size: .8rem;
          }
        }

        .box-text {
          margin-top: 15px;
          padding: 15px 15px 15px 0;
        }
      }
    }
  }
}

//====================================================================
// 768-992px
//====================================================================
@media screen and(max-width: 992px) {
  .container {
    width: 720px;
  }
}

//====================================================================
// 576-768px
//====================================================================
@media screen and(max-width: 768px) {
  .container {
    width: 540px;
  }
}

//====================================================================
// 340-576px
//====================================================================
@media screen and(max-width: 576px) {
  .container {
    width: 340px;
  }

  .slide-item {
    .wrapper {
      .left {
        width: 100%;

        &-bg {
          width: 320px;
          height: 320px;
        }
      }

      .right {
        width: 100%;

        h3 {
          font-size: 1.2rem;
        }

        h4 {
          font-size: .8rem;
        }

        ul {
          margin-top: 15px;
          flex-direction: column;

          li {
            width: 100%;

            p {
              font-size: .8rem;
            }
          }
        }

        .box-text {
          margin-top: 15px;
          padding: 15px 15px 15px 0;
        }
      }
    }
  }
}
