@import "../../components/vars";

.tariffs {
  p {
    margin: 15px auto;
    width: 80%;
    text-align: center;
    color: $text;
  }

  .wrapper {
    margin-top: 70px;
    justify-content: space-between;

    .left {
      width: 50%;
      margin: 0;

      h3 {
        display: block;
        margin-bottom: 35px;
      }

      ul {
        li {
          position: relative;
          padding-left: 21px;
          margin: 15px 0;
          line-height: 1.6;
          color: #211b19;

          &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 7px;
            width: 5px;
            height: 5px;
            background-color: $green;
            @include border-radius(50%);
          }
        }
      }
    }

    .right {
      width: 47%;

      .tariffs-slider {
        .swiper-container .swiper-pagination {
          bottom: 20px;
        }

        .swiper-slide {
          &:nth-child(1) {
            h3 {
              background-color: $brown;
            }
          }

          &:nth-child(2) {
            h3 {
              background-color: $green;
            }
          }

          &:nth-child(3) {
            h3 {
              background-color: $blue;
            }
          }

          &:nth-child(4) {
            h3 {
              background-color: $yellow;
            }
          }
        }

        .slide-item {
          width: 125px;
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.07);
          overflow: hidden;
          margin: 0 13px 20px;
          padding-bottom: 0;
          @include border-radius(10px);

          h3 {
            padding: 10px 5px 5px;
            text-align: center;
            color: $white;
          }

          ul {
            li {
              margin: 0 auto 26px;

              img {
                height: 18px;
                margin: auto;
              }

              &:nth-child(1) {
                margin-top: 20px;
              }

              &:nth-child(15) {
                margin-top: 55px;
              }
            }
          }

          span {
            display: block;
            margin: auto 10px;
            font-weight: 700;
            font-size: 20px;
            letter-spacing: 1px;
            color: $black;
            text-align: center;
            padding: 10px 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }

  .wrapper-down {
    margin-top: 35px;
    text-align: center;

    .btn {
      margin: 30px auto 0;
    }
  }
}

// MediaQueries
//====================================================================
// 1200-1350px
//====================================================================
@media screen and(max-width: 1350px) {
  .container {
    width: 1180px;
  }

  .tariffs .wrapper .right .tariffs-slider .slide-item {
    width: 105px;
  }
}

//====================================================================
// 992-1200px
//====================================================================
@media screen and(max-width: 1200px) {
  .container {
    width: 960px;
  }

  .tariffs .wrapper {
    .left {
      width: 70%;
    }

    .right {
      width: 30%;

      .tariffs-slider .slide-item {
        width: 80%;
      }
    }
  }
}

//====================================================================
// 768-992px
//====================================================================
@media screen and(max-width: 992px) {
  .container {
    width: 720px;
  }

  .tariffs .wrapper {
    .left {
      width: 80%;
    }

    .right {
      width: 20%;

      .tariffs-slider .slide-item {
        width: 80%;
      }
    }
  }
}

//====================================================================
// 576-768px
//====================================================================
@media screen and(max-width: 768px) {
  .container {
    width: 540px;
  }

  .tariffs .wrapper {
    .left {
      width: 85%;
    }

    .right {
      width: 15%;

      .tariffs-slider .slide-item {
        width: 80%;
      }

      span {
        font-size: 19px !important;
      }
    }
  }
}

//====================================================================
// 340-576px
//====================================================================
@media screen and(max-width: 576px) {
  .container {
    width: 340px;
  }

  .tariffs .wrapper {
    .left {
      width: 75%;

      ul li {
        font-size: 14px;
      }
    }

    .right {
      width: 25%;

      .tariffs-slider .slide-item {
        width: 78%;

        ul li {
          margin: 0 auto 42px;

          &:nth-child(1) {
            margin-top: 22px;
          }

          &:nth-child(5) {
            margin-bottom: 20px;
          }

          &:nth-child(6) {
            margin-bottom: 20px;
          }

          &:nth-child(9) {
            margin-bottom: 16px;
          }

          &:nth-child(10) {
            margin-bottom: 17px;
          }

          &:nth-child(11) {
            margin-bottom: 22px;
          }

          &:nth-child(14) {
            margin-bottom: 65px;
          }
        }
      }
    }
  }
}
