@import "../../components/vars";

#card {
  display: flex;
  overflow: hidden;
  @include border-radius(20px);

  .desc {
    display: block;
    margin: auto 0;
    padding: 15px 25px;
    border-left: 10px solid;

    span {
      position: relative;
      display: block;
      color: $black;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.4;
    }

    p {
      margin-top: 10px;
      min-height: 50px;
    }
  }

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}
