@import "../../components/vars";

.banner-box {
  position: relative;

  .wrap {
    height: 350px;

    img {
      -o-object-fit: cover;
      object-fit: cover;
      height: 100%;
      width: 100%;
      overflow: hidden;
      @include border-radius(20px);
    }
  }

  .text {
    text-align: center;

    h2 {
      width: 100%;
      color: $white;
      padding-bottom: 0;
      margin-bottom: 0;
      text-align: center;
      text-shadow: 0 0 5px $white;

      &::after {
        display: none;
      }
    }

    p {
      margin: 10px auto 25px;
      text-shadow: 0 0 2px $white;
      color: $white;
    }

    .btn {
      width: 195px;
      margin: auto;
    }
  }
}
