@import "../../components/vars";

.wehave {
  .wrap {
    .wrapper {
      ul {
        li {
          margin-bottom: 25px;
        }
      }
    }
  }
}
