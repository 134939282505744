@import "../vars";

.logo {
  width: 380px;
  display: flex;
  justify-content: space-between;

  img {
    width: auto;
    height: 20px;
  }
}
