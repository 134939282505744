@import "../../components/vars";

.list-items {
  .wrapper {
    .left {
      ul {
        margin-top: 50px;
        margin-bottom: 0;
        padding-left: 0;

        li {
          position: relative;
          margin: 35px 0;
          padding-left: 45px;
          color: $text;
          font-size: 18px;

          &::before {
            content: "\2192";
            display: block;
            position: absolute;
            top: -10px;
            left: 0;
            font-size: 30px;
            color: $btn;
          }
        }
      }
    }

    .right {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      img {
        &:nth-child(1) {
          width: 330px;
          height: auto;
        }

        &:nth-child(2) {
          width: 250px;
          height: auto;
          margin-top: auto;
          margin-bottom: 90px;
        }

        &:nth-child(3) {
          width: 400px;
          height: auto;
          margin-top: -70px;
          margin-left: auto;
          margin-right: 0;
        }
      }
    }
  }
}

// MediaQueries
//====================================================================
// 1200-1350px
//====================================================================
@media screen and(max-width: 1350px) {
  .container {
    width: 1180px;
  }

  .list-items .wrapper .right img:nth-child(1) {
    width: 280px;
  }
}

//====================================================================
// 992-1200px
//====================================================================
@media screen and(max-width: 1200px) {
  .container {
    width: 960px;
  }

  .list-items .wrapper .right {
    img:nth-child(1) {
      width: 250px;
    }

    img:nth-child(2) {
      width: 175px;
    }
  }
}

//====================================================================
// 768-992px
//====================================================================
@media screen and(max-width: 992px) {
  .container {
    width: 720px;
  }

  .list-items .wrapper .right {
    img:nth-child(1) {
      width: 370px;
    }

    img:nth-child(2) {
      width: 320px;
    }

    img:nth-child(3) {
      width: 450px;
    }
  }
}

//====================================================================
// 576-768px
//====================================================================
@media screen and(max-width: 768px) {
  .container {
    width: 540px;
  }

  .list-items .wrapper .right {
    img:nth-child(1) {
      width: 300px;
    }

    img:nth-child(2) {
      width: 215px;
    }
  }
}

//====================================================================
// 340-576px
//====================================================================
@media screen and(max-width: 576px) {
  .container {
    width: 340px;
  }

  .list-items .wrapper .right {
    img:nth-child(1) {
      width: 175px;
    }

    img:nth-child(2) {
      width: 140px;
    }

    img:nth-child(3) {
      width: 300px;
    }
  }
}
