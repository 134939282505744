@import "../../components/vars";

.offer {
  position: relative;
  padding: 0 0 35px;

  &-bg {
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    width: auto;
    min-height: 700px;

    img {
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      min-height: inherit;
    }
  }

  .wrap {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translate(0, -50%);

    &-title {
      width: 820px;

      h3 {
        position: relative;
        width: 80%;
        margin: 40px 0;
        padding-left: 25px;
        color: $black;

        &::before {
          position: absolute;
          content: "";
          display: block;
          top: 9px;
          left: 0;
          background-color: $btn;
          width: 8px;
          height: 8px;
          @include border-radius(50%);
        }
      }
    }
  }
}

// Media Queries
// ====================================================================
// 1200-1350px
//====================================================================
@media screen and (max-width: 1350px) {
  .container {
    width: 1180px;
  }
}

//====================================================================
// 992-1200px
//====================================================================
@media screen and (max-width: 1200px) {
  .container {
    width: 960px;
  }
}

//====================================================================
// 768-992px
//====================================================================
@media screen and (max-width: 992px) {
  .container {
    width: 720px;
  }

  .offer .wrap-title {
    width: 100%;

    h3 {
      width: 100%;
    }
  }
}

//====================================================================
// 576-768px
//====================================================================
@media screen and (max-width: 768px) {
  .container {
    width: 540px;
  }

  .offer {
    height: 100vh;

    .offer-bg-max {
      display: none;
    }

    .offer-bg-min {
      display: block !important;
    }

    img {
      height: 100vh;
    }
  }
}

//====================================================================
// 340-576px
//====================================================================
@media screen and (max-width: 576px) {
  .container {
    width: 340px;
  }
}
