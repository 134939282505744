@import "../../components/vars";

#protection {
  #protection-drons {
    .slide-item .wrapper .left-bg {
      background-color: $blue;
    }
  }

  #technics {
    .lng-protection_technics_h2 {
      text-align: center;
      margin: 0 auto 55px;

      &::after {
        left: 50%;
        @include transform(-50%, 0);
      }
    }
  }

  #protection-park-technics {
    .slide-item .wrapper .left-bg {
      background-color: $yellow;
    }
  }

  #protection-result {
    h2 {
      text-align: center;
      margin: 0 auto 55px;

      &::after {
        left: 50%;
        @include transform(-50%, 0);
      }
    }
  }

  #fertilizers-brands-slider .swiper-container {
    filter: none !important;
  }
}

// MediaQueries
//====================================================================
// 1200-1350px
//====================================================================
@media screen and(max-width: 1350px) {
  .container {
    width: 1180px;
  }
}

//====================================================================
// 992-1200px
//====================================================================
@media screen and(max-width: 1200px) {
  .container {
    width: 960px;
  }
}

//====================================================================
// 768-992px
//====================================================================
@media screen and(max-width: 992px) {
  .container {
    width: 720px;
  }

  #protection-plant,
  #protection-logistics_water {
    .wrap .wrapper {
      .left {
        order: 0 !important;
      }
    }
  }
}

//====================================================================
// 576-768px
//====================================================================
@media screen and(max-width: 768px) {
  .container {
    width: 540px;
  }
}

//====================================================================
// 340-576px
//====================================================================
@media screen and(max-width: 576px) {
  .container {
    width: 340px;
  }
}
