@import "../vars";

.btn-scroll-up {
  display: none;
  cursor: pointer;
  position: fixed;
  z-index: 555;
  bottom: 50px;
  right: 35px;
  height: 50px;
  font-size: 40px;
  color: $black;
  @include transition(0.2s);

  &:hover {
    color: $yellow;
  }
}
