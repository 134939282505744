@import "../../components/vars";

#politics {
  padding-bottom: 50px;

  p {
    h3 {
      margin-top: 35px;
    }
  }
}
