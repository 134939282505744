@import "../vars";

#burger {
  position: absolute;
  width: 60px;
  height: 50px;
  top: 10px;
  right: 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;

  .burger-i {
    position: absolute;
    z-index: 777;
    top: 9px;
    left: 10px;

    span {
      display: inline-block;
      position: absolute;
      height: 3px;
      width: 50px;
      background-color: $black;
      transform: rotate(0deg);
      @include transition(0.2s);
      @include border-radius(5px);

      &:nth-child(1) {
        top: 13px;
      }

      &:nth-child(2) {
        top: 23px;
      }
    }
  }

  &.active .burger-i {
    span:nth-child(1) {
      top: 15px;
      transform: rotate(45deg);
    }

    span:nth-child(2) {
      top: 15px;
      transform: rotate(-45deg);
    }
  }
}
