@import "../../components/vars";

#homepage {
  #grows {
    .right img {
      filter: none;
    }
  }

  #technics {
    #technics-slider {
      .swiper-slide {
        img {
          height: 50px;
          @include border-radius(0);
        }
      }
    }
  }

  #for-who,
  #agropark {
    h2 {
      width: 100%;
    }
  }
}
