@import "../../components/vars";

#sowing {
  .offer-radius {
    height: 1475px;
  }

  #principles {
    margin-top: 200px;
  }

  #park-technics-slider {
    .slide-item .wrapper .left-bg {
      background-color: $blue;
    }
  }

  #john-deere-slider,
  #claas-slider {
    margin-top: 50px;

    .swiper-container {
      filter: none !important;

      .swiper-slide img {
        @include border-radius(0 !important);
      }
    }
  }

  #diagnostics {
    padding-bottom: 100px;
  }

  #benefits-claas {
    h2 {
      width: 100%;
    }

    ul li {
      &:nth-child(1) {
        &::before, b {
          color: $brown;
        }
      }

      &:nth-child(2) {
        &::before, b {
          color: $blue;
        }
      }

      &:nth-child(3) {
        &::before, b {
          color: $green;
        }
      }

      &:nth-child(4) {
        &::before, b {
          color: $yellow;
        }
      }
    }
  }

  #park-seeding-slider {
    .slide-item .wrapper .left-bg {
      background-color: $yellow;
    }
  }

  #sowing-banner-box-end {
    padding-top: 0;
  }


}

// MediaQueries
//====================================================================
// 1200-1350px
//====================================================================
@media screen and(max-width: 1350px) {
  .container {
    width: 1180px;
  }

  #sowing {
    .offer-radius {
      height: 1435px;
    }
  }
}

//====================================================================
// 992-1200px
//====================================================================
@media screen and(max-width: 1200px) {
  .container {
    width: 960px;
  }

  #sowing {
    .offer-radius {
      height: 1440px;
    }

    #principles {
      margin-top: 150px;
    }
  }
}

//====================================================================
// 768-992px
//====================================================================
@media screen and(max-width: 992px) {
  .container {
    width: 720px;
  }

  #sowing {
    .offer-radius {
      height: 1850px;
    }

    #principles {
      margin-top: 120px;

      .wrap {
        .wrapper {
          .left {
            order: 1 !important;
          }

          .right {
            order: 2 !important;
          }
        }
      }
    }
  }
}

//====================================================================
// 576-768px
//====================================================================
@media screen and(max-width: 768px) {
  .container {
    width: 540px;
  }

  #sowing {
    #principles {
      margin-top: 170px;
    }
  }
}

//====================================================================
// 340-576px
//====================================================================
@media screen and(max-width: 576px) {
  .container {
    width: 340px;
  }

  #sowing {
    .offer-radius {
      height: 1910px;
    }

    #principles {
      margin-top: 120px;
    }
  }
}
