@import "../vars";

// Navigation & Navigate
.navigation {
  width: 100%;

  nav {
    width: 85%;
    margin: auto;

    .wrapper {
      width: 100%;
      position: relative;
    }

    ul {
      display: flex;
      position: relative;
      justify-content: space-between;
      width: 100%;

      li {
        cursor: pointer;
        position: relative;

        a {
          font-weight: normal;
          font-size: 1rem;
          color: inherit;
          line-height: 1;
          color: $text;
        }

        &:hover a {
          color: $hover;
        }

        .active {
          pointer-events: none;
          color: $active !important;
        }
      }
    }
  }
}

.navigate {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  background-color: $white;
  align-items: center;

  nav {
    height: 75% !important;

    ul {
      display: flex;
      position: absolute;
      top: 50%;
      left: 50%;
      flex-direction: column;
      @include translate(-50%, -50%);

      li {
        text-align: center;
        display: contents;

        a {
          font-size: 1.5rem !important;
          color: $black;
          margin: 7% auto;

          &:hover {
            color: $hover;
          }

          &.active {
            color: $active;
          }
        }
      }
    }
  }
}
