@import "../../components/vars";

.offer-radius {
  position: relative;
  height: 1320px;

  &-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    overflow-x: hidden;
    margin-left: -450px;

    img {
      width: 1300px;
    }

    &-radar {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      margin-top: -30px;
      margin-left: 380px;

      .wrap {
        position: relative;

        .circle-line {
          transform-origin: center;
          box-sizing: content-box;
          -webkit-box-sizing: content-box;
          border: 2px solid #31b5ff;
          width: 650px;
          height: 650px;
          position: absolute;
          bottom: -110px;
          right: -114px;
          -webkit-animation: circle-anim 4.1s infinite ease-in-out !important;
          animation: circle-anim 4.1s infinite ease-in-out !important;
          opacity: 0.5;
          @include border-radius(100%);
          @include transition(0.3s);
        }

        .circle-fill {
          transform-origin: center;
          box-sizing: content-box;
          -webkit-box-sizing: content-box;
          background-color: #31b5ff;
          width: 550px;
          height: 550px;
          position: absolute;
          bottom: -58px;
          right: -62px;
          -webkit-animation: circle-fill-anim 4.1s infinite ease-in-out;
          animation: circle-fill-anim 4.1s infinite ease-in-out;
          @include border-radius(100%);
          @include transition(0.3s);
        }

        .circle-center {
          transform-origin: center;
          background-color: #31b5ff;
          box-sizing: content-box;
          -webkit-box-sizing: content-box;
          width: 430px;
          height: 430px;
          position: absolute;
          bottom: 0;
          right: 0;
          @include border-radius(100%);
          opacity: 0.5;
        }
      }
    }
  }

  &-wrap {
    position: absolute;
    z-index: 1;
    top: 170px;
    left: 0;
    width: 100%;

    &-title {
      width: 660px;

      h3 {
        position: relative;
        margin: 30px 0 55px;
        padding-left: 25px;
        color: $black;

        &::before {
          position: absolute;
          content: "";
          display: block;
          top: 9px;
          left: 0;
          background-color: $btn;
          width: 8px;
          height: 8px;
          @include border-radius(50%);
        }
      }
    }
  }

  .list-image-text {
    margin-top: 200px;
  }
}

@-webkit-keyframes circle-anim {
  0% {
    transform: rotate(0deg) scale(0.5) skew(1deg);
    -webkit-transform: rotate(0deg) scale(0.5) skew(1deg);
    -moz-transform: rotate(0deg) scale(0.5) skew(1deg);
    -ms-transform: rotate(0deg) scale(0.5) skew(1deg);
    -o-transform: rotate(0deg) scale(0.5) skew(1deg);
    opacity: 0.1;
  }

  30% {
    transform: rotate(0deg) scale(0.815) skew(1deg);
    -webkit-transform: rotate(0deg) scale(0.815) skew(1deg);
    -moz-transform: rotate(0deg) scale(0.815) skew(1deg);
    -ms-transform: rotate(0deg) scale(0.815) skew(1deg);
    -o-transform: rotate(0deg) scale(0.815) skew(1deg);
    opacity: 0.5;
  }

  100% {
    transform: rotate(0deg) scale(1) skew(1deg);
    -webkit-transform: rotate(0deg) scale(1) skew(1deg);
    -moz-transform: rotate(0deg) scale(1) skew(1deg);
    -ms-transform: rotate(0deg) scale(1) skew(1deg);
    -o-transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@keyframes circle-anim {
  0% {
    transform: rotate(0deg) scale(0.5) skew(1deg);
    -webkit-transform: rotate(0deg) scale(0.5) skew(1deg);
    -moz-transform: rotate(0deg) scale(0.5) skew(1deg);
    -ms-transform: rotate(0deg) scale(0.5) skew(1deg);
    -o-transform: rotate(0deg) scale(0.5) skew(1deg);
    opacity: 0.1;
  }

  30% {
    transform: rotate(0deg) scale(0.815) skew(1deg);
    -webkit-transform: rotate(0deg) scale(0.815) skew(1deg);
    -moz-transform: rotate(0deg) scale(0.815) skew(1deg);
    -ms-transform: rotate(0deg) scale(0.815) skew(1deg);
    -o-transform: rotate(0deg) scale(0.815) skew(1deg);
    opacity: 0.5;
  }

  100% {
    transform: rotate(0deg) scale(1) skew(1deg);
    -webkit-transform: rotate(0deg) scale(1) skew(1deg);
    -moz-transform: rotate(0deg) scale(1) skew(1deg);
    -ms-transform: rotate(0deg) scale(1) skew(1deg);
    -o-transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@-webkit-keyframes circle-fill-anim {
  0% {
    transform: rotate(0deg) scale(0.5) skew(1deg);
    -webkit-transform: rotate(0deg) scale(0.5) skew(1deg);
    -moz-transform: rotate(0deg) scale(0.5) skew(1deg);
    -ms-transform: rotate(0deg) scale(0.5) skew(1deg);
    -o-transform: rotate(0deg) scale(0.5) skew(1deg);
    opacity: 0.2;
  }

  50% {
    transform: rotate(0deg) scale(1) skew(1deg);
    -webkit-transform: rotate(0deg) scale(1) skew(1deg);
    -moz-transform: rotate(0deg) scale(1) skew(1deg);
    -ms-transform: rotate(0deg) scale(1) skew(1deg);
    -o-transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.2;
  }

  100% {
    transform: rotate(0deg) scale(0.5) skew(1deg);
    -webkit-transform: rotate(0deg) scale(0.5) skew(1deg);
    -moz-transform: rotate(0deg) scale(0.5) skew(1deg);
    -ms-transform: rotate(0deg) scale(0.5) skew(1deg);
    -o-transform: rotate(0deg) scale(0.5) skew(1deg);
    opacity: 0.2;
  }
}

@keyframes circle-fill-anim {
  0% {
    transform: rotate(0deg) scale(0.5) skew(1deg);
    -webkit-transform: rotate(0deg) scale(0.5) skew(1deg);
    -moz-transform: rotate(0deg) scale(0.5) skew(1deg);
    -ms-transform: rotate(0deg) scale(0.5) skew(1deg);
    -o-transform: rotate(0deg) scale(0.5) skew(1deg);
    opacity: 0.2;
  }

  50% {
    transform: rotate(0deg) scale(1) skew(1deg);
    -webkit-transform: rotate(0deg) scale(1) skew(1deg);
    -moz-transform: rotate(0deg) scale(1) skew(1deg);
    -ms-transform: rotate(0deg) scale(1) skew(1deg);
    -o-transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.2;
  }

  100% {
    transform: rotate(0deg) scale(0.5) skew(1deg);
    -webkit-transform: rotate(0deg) scale(0.5) skew(1deg);
    -moz-transform: rotate(0deg) scale(0.5) skew(1deg);
    -ms-transform: rotate(0deg) scale(0.5) skew(1deg);
    -o-transform: rotate(0deg) scale(0.5) skew(1deg);
    opacity: 0.2;
  }
}

// MediaQueries
//====================================================================
// 1200-1350px
//====================================================================
@media screen and(max-width: 1350px) {
  .container {
    width: 1180px;
  }

  .offer-radius {
    height: 1250px;

    .list-image-text {
      margin-top: 130px;
    }

    &-bg-radar {
      margin-top: 20px;
      margin-left: 360px;
    }
  }
}

//====================================================================
// 992-1200px
//====================================================================
@media screen and(max-width: 1200px) {
  .container {
    width: 960px;
  }

  .offer-radius {
    height: 1200px;

    .list-image-text {
      margin-top: 80px;
    }

    &-bg-radar {
      margin-top: 50px;
    }
  }
}

//====================================================================
// 768-992px
//====================================================================
@media screen and(max-width: 992px) {
  .container {
    width: 720px;
  }

  .offer-radius-bg-radar {
    margin-left: 340px;
  }
}

//====================================================================
// 576-768px
//====================================================================
@media screen and(max-width: 768px) {
  .container {
    width: 540px;
  }

  .offer-radius {
    height: 1450px;

    &-wrap-title {
      width: 100%;
    }

    &-bg-max {
      display: none;
    }

    &-bg-min {
      display: block !important;
    }

    &-bg-radar {
      margin-top: 100px;
      margin-left: 180px;
      transform: scale(0.7);
    }
  }


}

//====================================================================
// 340-576px
//====================================================================
@media screen and(max-width: 576px) {
  .container {
    width: 340px;
  }

  .offer-radius {
    height: 2150px;

    .list-image-text {
      margin-top: 40px;
    }
  }
}
