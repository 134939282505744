@import "../../components/vars";

.services {
  .container {
    .wrap {
      .card-item:nth-child(3),
      .card-item:nth-child(4) {
        margin-bottom: 0;
      }
    }
  }
}
