@import "../../components/vars";

.text-list {
  .wrapper {
    .left {
      .title-box {
        display: flex;
        margin-bottom: 50px;

        img {
          height: 70px;
        }

        h3 {
          margin: auto 15px;
        }
      }

      p {
        margin-bottom: 25px;
        padding-left: 20px;
        position: relative;

        &:before {
          position: absolute;
          left: 0;
          top: 8px;
          content: "";
          display: block;
          width: 5px;
          height: 5px;
          background-color: $green;
          @include border-radius(50%);
        }
      }
    }

    .right {
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
          width: 48%;
          margin: 10px 0;
          padding: 20px;
          color: $text;
          box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
          border-top: 5px solid;
          @include border-radius(10px);

          &:nth-child(1) {
            border-color: $brown;

            b {
              color: $brown;
            }
          }

          &:nth-child(2) {
            border-color: $blue;

            b {
              color: $blue;
            }
          }

          &:nth-child(3) {
            border-color: $green;

            b {
              color: $green;
            }
          }

          &:nth-child(4) {
            border-color: $yellow;

            b {
              color: $yellow;
            }
          }

          &:nth-child(5) {
            border-color: $blue;

            b {
              color: $blue;
            }
          }

          &:nth-child(6) {
            border-color: $brown;

            b {
              color: $brown;
            }
          }
        }
      }
    }
  }
}

// MediaQueries
//====================================================================
// 1200-1350px
//====================================================================
@media screen and(max-width: 1350px) {
  .container {
    width: 1180px;
  }
}

//====================================================================
// 992-1200px
//====================================================================
@media screen and(max-width: 1200px) {
  .container {
    width: 960px;
  }

  .text-list {
    .wrapper {
      .right {
        ul {
          li {
            width: 100%;
          }
        }
      }
    }
  }
}

//====================================================================
// 768-992px
//====================================================================
@media screen and(max-width: 992px) {
  .container {
    width: 720px;
  }
}

//====================================================================
// 576-768px
//====================================================================
@media screen and(max-width: 768px) {
  .container {
    width: 540px;
  }
}

//====================================================================
// 340-576px
//====================================================================
@media screen and(max-width: 576px) {
  .container {
    width: 340px;
  }
}
