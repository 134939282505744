@import "./components/vars";

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

html {
  width: 100%;
  font-size: 18px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

body {
  font-family: $font-custom;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

body.not-scroll {
  overflow: hidden;
}

section {
  padding: 50px 0;
}

.container {
  margin: 0 auto;
  width: 1280px;
}

ul[class],
ol[class] {
  padding: 0;
  list-style: none;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style-type: none;
}

img {
  display: block;
  max-width: 100%;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

:focus {
  outline: none;
}

a,
button,
input {
  text-decoration: none;
  cursor: pointer;
  @include transition(0.2s);
}

@media (prefers-reduced-motion: reduce) {
  * {
    -webkit-animation-duration: 0.01ms !important;
    animation-duration: 0.01ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input,
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 1rem !important;
  }
}

h1 {
  text-transform: uppercase;
  font-size: 2.7rem;
  line-height: 1.2;
  font-weight: 900;
  color: $black;
}

h2 {
  width: 47%;
  text-align: left;
  position: relative;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.2px;
  color: $black;
  padding-bottom: 50px;
  margin-bottom: 55px;

  &::after {
    position: absolute;
    bottom: 0;
    content: '';
    display: block;
    width: 150px;
    height: 5px;
    background-color: $yellow;
    margin: auto;
  }
}

h3 {
  font-size: 1.2rem;
  line-height: 1.3;
  font-weight: normal;
  color: $black;
}

h4 {
  font-size: 1rem;
}

p {
  color: $text;
  font-size: 1rem;
}

ul {
  padding: 0;
}

input {
  font-size: 1rem;
  text-align: center;
}

// Custom styles
.df {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.dn {
  display: none;
}

.fww {
  flex-wrap: wrap;
}

.a-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.shadow {
  filter: drop-shadow(5px 5px 25px rgba(0, 0, 0, .25));
}

.box-shadow {
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.07);
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .left {
    position: relative;
    width: 47%;
    margin: auto 0;
  }

  .right {
    position: relative;
    width: 47%;
    margin: auto 0;
  }
}

// Swiper Slider
.slider {
  .swiper-slide {
    opacity: 0 !important;

    &.swiper-slide-active {
      opacity: 1 !important;
    }
  }

  .swiper-container {
    position: relative;
    display: flex;
    flex-direction: column;

    .swiper-wrapper {
      order: 1;
    }

    .swiper-pagination {
      order: 2;
      position: relative;
      bottom: 0;
      margin-top: 10px;
    }
  }
}

.tariffs-slider {
  .swiper-slide {
    opacity: 1 !important;

    &.swiper-slide-active {
      opacity: 1 !important;
    }
  }
}

// Media Queries
// ====================================================================
// 1200-1350px
//====================================================================
@media screen and (max-width: 1350px) {
  .container {
    width: 1180px;
  }
}

//====================================================================
// 992-1200px
//====================================================================
@media screen and (max-width: 1200px) {
  .container {
    width: 960px;
  }
}

//====================================================================
// 768-992px
//====================================================================
@media screen and (max-width: 992px) {
  .container {
    width: 720px;
  }

  h2 {
    font-size: 1.5rem;
  }

  .wrap {
    h2 {
      width: 100%;
    }

    .wrapper {
      flex-direction: column;

      .left,
      .right {
        width: 100%;
      }

      .left {
        margin-bottom: 50px;

        .shadow {
          width: 100%;
        }
      }
    }
  }

  #tariffs-slider {
    .nav-slider {
      margin-left: 8px;
    }
  }
}

//====================================================================
// 576-768px
//====================================================================
@media screen and (max-width: 768px) {
  .container {
    width: 540px;
  }

  h1 {
    font-size: 2.2rem;
  }

  h2 {
    width: 100%;
  }

  #tariffs-slider {
    .nav-slider {
      margin-left: -40px;
    }
  }
}

//====================================================================
// 340-576px
//====================================================================
@media screen and (max-width: 576px) {
  .container {
    width: 340px;
  }

  h1 {
    font-size: 2rem;
  }

  .slider .nav-slider {
    margin-top: 20px;
  }
}
